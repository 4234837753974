(function( $ ) {

    'use strict';
   //console.log('DP Monarch');
    var mobileShareButton = $('.et_social_mobile_button');
    if (mobileShareButton.length)
    {
        var mobile_div = $( '.et_social_mobile' );
        mobile_div.fadeToggle( 600 );
        mobileShareButton.addClass( 'et_social_active_button' );
        if ( mobile_div.hasClass( 'et_social_opened' ) ) {
            $( '.et_social_mobile_overlay' ).fadeToggle( 600 );
            mobile_div.removeClass( 'et_social_opened' );
            mobile_div.find( '.et_social_networks' ).fadeToggle( 600 );
        } 
    }

})( jQuery );
